
	import http from "../../../../common/services/http";
	import { computed, defineComponent, ref, reactive, onMounted } from "vue";
	//import { ModelListSelect } from "vue-search-select";

	export default defineComponent({
		// components: {
		// 	ModelListSelect,
		// },
		setup(props) {
			const headers = {

				name: "Name",
				email: "Email Address",
				phone: "Phone Number",
				role: "Role",
			};

			const roles = ref([
				{ id: "customer", title: "Customer" },
				{ id: "accountant", title: "Accountant" },
				{ id: "sales", title: "Salesperson" },
				{ id: "admin", title: "Administrator" },
			]);

			const records = ref({
				data: [],
				first_page: 1,
				last_page: 1,
			});

			const record = reactive({
				first_name: "",
				last_name: "",
				email: "",
				phone: "",
				role: { id: "customer", title: "Customer" },
			});

			const search = ref("");

			const processing = ref(false);
			const created = ref(false);
			const updated = ref(false);

			function fetchRecords(page = 1) {
				processing.value = true;
				http.get(`/api/users?page=${page}`).then((res) => {
					records.value = res.data;
					processing.value = false;
				});
			}

			function searchRecords(e: any) {
				processing.value = true;
				http.get(`/api/users?s=${search.value}`).then((res) => {
					records.value = res.data;
					processing.value = false;
				});
			}

			function createRecord() {
				http.post("/api/users", record)
					.then((res) => {
						if (res.data.success) {
							created.value = true;
						}
					})
					.finally(() => {
						document.getElementById("hideNewForm")?.click();
						fetchRecords();
					});
			}

			function updateRecord(record: any) {
				record._method = 'PUT'
				http.post(`/api/users/${record.id}`, record)
					.then((res) => {
						if (res.data.success) {
							updated.value = true;
						}
					})
					.finally(() => {
						document.getElementById("hideEditForm")?.click();
						fetchRecords();
					});
			}

			function deleteRecord(id: any) {
				http.post(`/api/users/${id}`, { _method: "DELETE" }).then((res) => {
					fetchRecords();
				});
			}

			const searchInput = ref("");
			const handleSearchInput = (event: any) => {
				searchInput.value = event.target.value;
			};

			const visibleOptions = computed(() => {
				const re = new RegExp(
					searchInput.value.replace(/[.*+?^${}()|[]\]/g, "$&")
				);

				return roles.value.filter((option) => re.test(option.title));
			});

			onMounted(() => {
				fetchRecords();
			});

			return {
				headers,
				fetchRecords,
				processing,
				records,
				createRecord,
				created,
				record,
				updateRecord,
				updated,
				deleteRecord,
				roles,
				handleSearchInput,
				visibleOptions,
				search,
				searchRecords
			};
		},
	});
